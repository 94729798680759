export const getErrorMessage = (error: unknown, defaultErrorMessage = 'Unknown error'): string => {
  if (typeof error === 'string') {
    return error;
  }

  if (
    !!error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message;
  }

  return defaultErrorMessage;
};
